/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-cycle */
import React, { useCallback, useContext } from "react";
import { Checkbox } from "@mui/material";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { connect } from "react-redux";
import actions from "../../../store/actions/authActions";

import styles from "./StudentsRegistration2.module.scss";
import LinkedIn from "../../../assets/images/in.png";
import DropFiles from "../../../components/DropFiles/DropFiles";
import { StudentRegistrationContext } from "../index";
import calIcon from "../../../assets/images/calendar.png";
import "react-datepicker/dist/react-datepicker.css";

const initialValues = {
  school: "",
  education: "",
  educationCity: "",
  startEducation: "",
  endEducation: "",
  mayor: "",
  studyHere: false,
};

const validationSchema = Yup.object({
  school: Yup.string().required(),
  education: Yup.string().required(),
  educationCity: Yup.string().required(),
  startEducation: Yup.string().required(),
  endEducation: Yup.string().required(),
  mayor: Yup.string().required(),
});

const StudentsRegistrationForm2 = React.memo(({ setUserEducation }) => {
  const { changeStep, changeData } = useContext(StudentRegistrationContext);

  const nextStep = (values) => {
    setUserEducation({
      schoolName: values.school,
      educationLevel: values.education,
      startEducation: values.startEducation.toString(),
      endEducation: values.endEducation.toString(),
      isStudyingHereNow: values.studyHere,
      major: values.mayor,
      location: values.educationCity,
    });
    changeData(values);
    changeStep(3);
  };

  const handleSkip = useCallback(() => {
    changeStep(3);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={nextStep}
      validationSchema={validationSchema}
    >
      {({
        touched,
        errors,
        setFieldValue,
        values,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <form onSubmit={handleSubmit} className={styles.registration1}>
          <div className={styles.form}>
            <div className={styles.form__column}>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.school && errors.school
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  onBlur={handleBlur}
                  value={values.lastName}
                  name="school"
                  onChange={handleChange}
                />
                <label className={styles.formLabel}>School Name</label>
              </div>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.education && errors.education
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.education}
                  name="education"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label className={styles.formLabel}>Education Level</label>
              </div>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.educationCity && errors.educationCity
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.educationCity}
                  name="educationCity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label className={styles.formLabel}>City, Country</label>
              </div>
            </div>
            <div className={styles.form__column}>
              <div className={styles.date_row}>
                <div
                  className={
                    touched.startEducation && errors.startEducation
                      ? clsx(styles.date_column, styles.date_column__error)
                      : styles.date_column
                  }
                >
                  <DatePicker
                    selected={values.startEducation}
                    onChange={(val) => {
                      setFieldValue("startEducation", val);
                    }}
                    onBlur={handleBlur}
                    error={touched.startEducation && !!errors.startEducation}
                    placeholderText="Start Date"
                    className={styles.date_column__input}
                    name="startEducation"
                  />
                  <img
                    src={calIcon}
                    alt="date"
                    className={styles.date_column__icon}
                  />
                </div>
                <div
                  className={
                    touched.endEducation && errors.endEducation
                      ? clsx(styles.date_column, styles.date_column__error)
                      : styles.date_column
                  }
                >
                  <DatePicker
                    selected={values.endEducation}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      setFieldValue("endEducation", val);
                    }}
                    error={touched.endEducation && !!errors.endEducation}
                    placeholderText="End Date"
                    className={styles.date_column__input}
                    name="endEducation"
                  />
                  <img
                    src={calIcon}
                    alt="date"
                    className={styles.date_column__icon}
                  />
                </div>
              </div>
              <div className={styles.checkbox}>
                <Checkbox
                  size="small"
                  checked={values.studyHere}
                  name="studyHere"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{
                    color: "#5900F5",
                    "&.Mui-checked": {
                      color: "#5900F5",
                    },
                  }}
                />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <span>I'm currently studying here</span>
              </div>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.mayor && errors.mayor
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.mayor}
                  name="mayor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label className={styles.formLabel}>Major</label>
              </div>
            </div>
          </div>
          <div className="text_change">
            <DropFiles />
          </div>
          <div className={styles.next}>
            <button
              onClick={handleSkip}
              type="button"
              className={styles.skipButton}
            >
              Skip
            </button>
            <button type="submit" className={styles.next__button}>
              Next
            </button>
          </div>
          <div className={styles.upper_block}>
            <div className={styles.linkedIn}>
              <img src={LinkedIn} alt="" className={styles.linkedIn__image} />
              <span className={styles.linkedIn__text}>
                Import from LinkedIn
              </span>
            </div>
            <div className={styles.new_btn}>New</div>
          </div>
        </form>
      )}
    </Formik>
  );
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserEducation: (education) =>
    dispatch(actions.setUserEducation(education)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsRegistrationForm2);
