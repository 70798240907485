import React, { memo, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const DropDown = ({
  onChange,
  value,
  label,
  options = [],
  name,
  disabled,
  error,
}) => {
  const labelId = useMemo(() => `${label.toLowerCase()}-dropdown`, []);
  const selectId = useMemo(() => `${label.toLowerCase()}-select`, []);

  return (
    <FormControl
      required
      filled={value}
      error={!!error}
      sx={{
        borderRadius: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        "& .MuiOutlinedInput-input": {
          color: "white",
        },
        "& .MuiInputLabel-root": {
          color: "white",
          "&.Mui-error": {
            color: "white",
          },
          "& .Mui-error": {
            color: "white",
          },
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: "10px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "white",
          borderRadius: "10px",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "white",
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "white",
            borderWidth: "1px",
            borderRadius: "10px",
          },
        "& .MuiSvgIcon-root": {
          color: "rgba(255, 255, 255, 0.5)",
        },
      }}
      fullWidth
    >
      <InputLabel
        sx={{
          color: "white",
          opacity: 0.4,
          fontSize: "14px",
        }}
        id={labelId}
      >
        {label}
      </InputLabel>
      <Select
        id={selectId}
        name={name}
        onChange={onChange}
        value={value}
        labelId={labelId}
        label={label}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={uuidv4()} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(DropDown);
