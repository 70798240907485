import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RegisterChoose from "./pages/Auth/RegisterChoose";
import Register from "./pages/Auth/Register";
import Personality from "./pages/Dashboard/Personality";
import Challenges from "./pages/Dashboard/Challenges";
import StudentsRegistration from "./pages/StudentsRegistration";
import DashboardWrapper from "./components/DashboardWrapper";
import pageTypes from "./utils/constants";

const App = () => (
  <Routes>
    <Route index path="/auth" element={<RegisterChoose />} />
    <Route
      path="/auth/register-student"
      element={<Register type="student" title="Register as a Talent" />}
    />
    <Route
      path="/auth/login"
      element={
        <Register pageType={pageTypes.LOGIN} type="student" title="Login" />
      }
    />
    <Route
      path="/auth/register-provider"
      element={<Register type="provider" title="Register as Provider" />}
    />
    <Route
      path="/auth/subscribe"
      element={
        <Register type="subscribe" title="Subscribe on Match Excellence" />
      }
    />
    <Route
      index
      path="/auth/registration-student"
      element={<StudentsRegistration />}
    />

    <Route element={<Challenges />} path="dashboard/challenges" />
    <Route element={<Personality />} path="dashboard/personality" />
    <Route element={<DashboardWrapper />}>
      <Route element={<div />} path="dashboard/skills" />
      <Route element={<div />} path="dashboard/badges" />
      <Route element={<div />} path="dashboard/diplomas" />
      <Route element={<div />} path="dashboard/10-year-vision" />
    </Route>
    <Route path="/" element={<Navigate to="/auth" replace />} />
  </Routes>
);

export default App;
