import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useLocation, useNavigate } from "react-router-dom";
import { Radar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import Wrapper from "../Wrapper/Wrapper";
import SideBar from "../SideBar/SideBar";
import ProgressWrapper from "../SideBar/ProgressWrapper/ProgressWrapper";

import { ReactComponent as SettingsIcon } from "../../assets/images/Vector.svg";
// import { ReactComponent as ExitIcon } from "../../assets/images/exit.svg";
import { ReactComponent as GoBackIcon } from "../../assets/images/goBack.svg";
import ProfileIcon from "../../assets/images/blank-avatar.png";
import NuclearIcon from "../../assets/images/nuclear.png";
import styles from "./DashboardWrapper.module.css";
import progressStyles from "../SideBar/ProgressWrapper/InnerContent.module.scss";

const buttons = [
  { label: "Challenges", value: "challenges" },
  { label: "Skills", value: "skills" },
  { label: "Badges", value: "badges" },
  { label: "Diplomas", value: "diplomas" },
  { label: "Personality", value: "personality" },
  { label: "10 Year Vision", value: "10-year-vision" },
];

const achievements = [
  { value: "90", label: "Data Mining", background: "#5900f5" },
  { value: "80", label: "Leadership", background: "#8400EE" },
  { value: ">", label: "Personality", background: "#4E7CFF" },
];

const radarLabels = [
  ["CAD", 87],
  ["Data Mining", 45],
  ["CRM Platforms", 77],
  ["Diagnostics", 75],
  ["Prototyping", 50],
];

const data = {
  labels: radarLabels,
  datasets: [
    {
      label: "",
      data: [87, 45, 77, 75, 50],
      backgroundColor: "rgba(89, 0, 245, 0.3)",
      borderColor: "rgba(89, 0, 245, 1)",
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      grid: {
        color: ["rgba(255, 255, 255, .1)"],
      },
      angleLines: {
        color: ["rgba(255, 255, 255, .1)"],
      },
      ticks: {
        tickColor: ["rgba(255, 255, 255, .1)"],
        display: false,
        maxTicksLimit: 3,
      },
    },
  },
};

const DashboardWrapper = ({ children }) => {
  const [isRadarVisible, setIsRadarVisible] = useState(false);
  const { lastName, firstName, phone, accAvatar } = useSelector(
    (state) => state.auth.info
  );
  const email = useSelector((state) => state.auth.email);
  const { jobTitle, location } = useSelector((state) => state.auth.experience);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activePage = useMemo(() => {
    const pathArr = pathname.split("/");
    const path = pathArr[pathArr.length - 1];
    const index = buttons.findIndex((el) => el.value.toLowerCase() === path);
    return index !== -1 ? index : 0;
  }, [pathname]);

  const [activeTab, setActiveTab] = useState(buttons[activePage]);
  const [isUserDetailsVisible, setIsUserDetailsVisible] = useState(false);

  const handleTabChange = useCallback((e) => {
    const newIndex = buttons.findIndex(
      (el) => el.value.toLowerCase() === e.target.name
    );
    setActiveTab(buttons[newIndex !== -1 ? newIndex : 0]);
    const url = `/dashboard/${e.target.name.toLowerCase()}`;
    navigate(url);
  }, []);

  const handleToggle = useCallback(() => {
    setIsUserDetailsVisible(!isUserDetailsVisible);
  }, [isUserDetailsVisible]);

  const handleChangeRadarVisible = useCallback(() => {
    setIsRadarVisible(false);
  }, []);

  const handleOpenRadar = useCallback(() => {
    setIsRadarVisible(true);
  }, []);

  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Wrapper title="Dashboard">
            <p className={styles.subTitle}>
              Here you can find your earnings and how to win more experience on
              Match Excellence
            </p>
            <div className={styles.buttonContainer}>
              {buttons.map((el) => {
                const isActive = activeTab.value === el.value;
                return (
                  <button
                    key={uuidv4()}
                    type="button"
                    className={isActive ? styles.buttonActive : styles.button}
                    name={el.value}
                    onClick={handleTabChange}
                  >
                    <span className={styles.buttonText}>{el.label}</span>
                  </button>
                );
              })}
              <div className={styles.iconButton}>
                <SettingsIcon />
              </div>
            </div>
            {children}
          </Wrapper>
          <SideBar>
            <ProgressWrapper value={75}>
              <div className={progressStyles.inner_block}>
                <img alt="profile" src={accAvatar || ProfileIcon} />
                <div className={styles.levelContainer}>
                  <p className={styles.levelText}>
                    7<br />
                    LVL
                  </p>
                </div>
              </div>
            </ProgressWrapper>
            <div className={styles.userContainer}>
              <p className={styles.userName}>
                {firstName ? `${firstName} ${lastName}` : "Noora Al Hamdane"}
              </p>
              <p className={styles.userAddress}>
                {location || "Wolf of Wall Street"}
              </p>
              <button
                onClick={handleToggle}
                style={{ width: "272px", margin: "20px 0" }}
                type="button"
                className={styles.buttonActive}
              >
                <span className={styles.buttonText}>
                  {!isUserDetailsVisible ? "Show" : "Hide"} Visit Card
                </span>
              </button>
            </div>
            {isUserDetailsVisible && (
              <div className={styles.userDetailsContainer}>
                <div>
                  <p className={styles.userNameDetails}>
                    {firstName
                      ? `${firstName} ${lastName}`
                      : "Noora Al Hamdane"}
                  </p>
                  <p className={styles.userPosition}>
                    {jobTitle || "Financial Analyst"}
                  </p>
                </div>
                <div>
                  <p className={styles.userContactDetails}>
                    {phone || "+1 234 567 890"} <br />
                    {email || "example@example.com"} <br />
                    {/* maex.co/noorahamdane <br /> */}
                  </p>
                </div>
                <img
                  className={styles.bottomImage}
                  src={NuclearIcon}
                  alt="nuclear"
                />
              </div>
            )}
            {isRadarVisible ? (
              <div className={styles.radarContainer}>
                <div className={styles.goBackContainer}>
                  <button
                    style={{ background: "none" }}
                    type="button"
                    onClick={handleChangeRadarVisible}
                  >
                    <p className={styles.goBackIcon}>
                      <GoBackIcon />
                    </p>
                  </button>
                  <p className={styles.goBackText}>Hard skills</p>
                </div>
                <Radar width={300} height={300} data={data} options={options} />
              </div>
            ) : (
              <div className={styles.achievementsContainer}>
                {achievements.map((el) => (
                  <div
                    onClick={handleOpenRadar}
                    key={uuidv4()}
                    className={styles.achievementsBlock}
                  >
                    <div
                      style={{ background: el.background }}
                      className={styles.achievementValue}
                    >
                      <span>{el.value}</span>
                    </div>
                    <p className={styles.achievement}>{el.label}</p>
                  </div>
                ))}
              </div>
            )}
          </SideBar>
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
