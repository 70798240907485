import React from "react";
import styles from "./Wrapper.module.scss";

const Wrapper = React.memo(({ children, title }) => (
  <div className={styles.wrapper}>
    <span className={styles.wrapper_title}>{title}</span>
    {children}
  </div>
));

export default Wrapper;
