import TYPES from "../types";

const setUserEmailAndPassword = (payload) => ({
  type: TYPES.SET_USER,
  payload: { email: payload.email, password: payload.password },
});

const setUserToken = (payload) => ({
  type: TYPES.SET_USER_TOKEN,
  payload: { token: payload.token },
});

const setUserExperience = (payload) => ({
  type: TYPES.SET_EXPERIENCE,
  payload,
});

const setUserEducation = (payload) => ({
  type: TYPES.SET_EDUCATION,
  payload,
});

const setUserInformation = (payload) => ({
  type: TYPES.SET_USER_GENERAL_INFO,
  payload,
});

const setCentralTestInformation = (payload) => ({
  type: TYPES.SET_CENTRAL_TEST_INFO,
  payload,
});

export default {
  setUserEmailAndPassword,
  setUserToken,
  setUserExperience,
  setUserEducation,
  setUserInformation,
  setCentralTestInformation,
};
