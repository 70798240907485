import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from "./ProgressWrapper.module.scss";
import "./progress-circle.css";

const ProgressWrapper = React.memo(
  ({ children, initialValue = 5, value = 5 }) => {
    const [initValue, setInitValue] = useState(initialValue);

    useEffect(() => {
      setInitValue(value);
    }, []);

    return (
      <div className={styles.progress_wrapper}>
        <CircularProgress
          variant="determinate"
          value={initValue}
          sx={{
            color: "#5900F5",
          }}
        />
        <div className={styles.circle} />
        {children}
      </div>
    );
  }
);

export default ProgressWrapper;
