import TYPES from "../types";

const initialState = {
  email: "",
  password: "",
  token: "",
  info: {
    firstName: "",
    lastName: "",
    phone: "",
    description: "",
    linkedinProfile: "",
    accauntAvatar: "",
  },
  education: {
    schoolName: "",
    educationLevel: "",
    startEducation: "",
    endEducation: "",
    isStudyingHereNow: false,
    major: "",
    location: "",
  },
  experience: {
    jobTitle: "",
    companyName: "",
    location: "",
    description: "",
    startDate: "",
    endDate: "",
    isWorkingHereNow: false,
  },
  centralTestInfo: {
    assessmentId: "",
    userId: "",
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_USER:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
      };
    case TYPES.SET_USER_TOKEN:
      return { ...state, token: action.payload.token };
    case TYPES.SET_EXPERIENCE:
      return {
        ...state,
        experience: {
          jobTitle: action.payload.jobTitle,
          companyName: action.payload.companyName,
          location: action.payload.location,
          description: action.payload.description,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          isWorkingHereNow: action.payload.isWorkingHereNow,
        },
      };
    case TYPES.SET_EDUCATION:
      return {
        ...state,
        education: {
          major: action.payload.major,
          schoolName: action.payload.schoolName,
          location: action.payload.location,
          educationLevel: action.payload.educationLevel,
          startEducation: action.payload.startEducation,
          endEducation: action.payload.endEducation,
          isStudyingHereNow: action.payload.isStudyingHereNow,
        },
      };
    case TYPES.SET_USER_GENERAL_INFO:
      return {
        ...state,
        info: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone,
          description: action.payload.description,
          linkedinProfile: action.payload.linkedinProfile,
          accAvatar: action.payload.accAvatar,
        },
      };
    case TYPES.SET_CENTRAL_TEST_INFO: {
      return {
        ...state,
        centralTestInfo: {
          assessmentId: action.payload.assessmentId,
          userId: action.payload.userId,
        },
      };
    }
    default:
      return state;
  }
};
