/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import StudentsRegistration1 from "./StudentsRegistration1";
import StudentsRegistration2 from "./StudentsRegistration2";
import StudentsRegistration3 from "./StudentsRegistration3";
import Header from "../../components/Header/Header";
import "./registration.css";

export const StudentRegistrationContext = React.createContext({});

const StudentsRegistration = React.memo(() => {
  const [step, setStep] = useState(1);
  const [registrationData, setRegistrationData] = useState({});

  const setStepData = (data) =>
    setRegistrationData({ ...registrationData, ...data });

  return (
    <StudentRegistrationContext.Provider
      value={{
        data: registrationData,
        changeStep: setStep,
        changeData: setStepData,
      }}
    >
      <Header showStats={false} />
      {step === 1 ? (
        <StudentsRegistration1 />
      ) : step === 2 ? (
        <StudentsRegistration2 />
      ) : (
        <StudentsRegistration3 />
      )}
    </StudentRegistrationContext.Provider>
  );
});

export default StudentsRegistration;
