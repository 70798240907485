import React, { memo, useCallback, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../store/actions/authActions";

import styles from "./DraggableItem.module.css";
import { centralTestAxios, queryGenerator } from "../../api";
import {
  CREATE_CANDIDATE,
  INVITE_CANDIDATE,
  PENDING_ASSESSMENT,
} from "../../api/urls";

const DEFAULT_TEST_ID = 144;

const DraggableDashboardItem = ({
  block,
  isDragging,
  setCentralTestInformation,
  user,
  clickable,
}) => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: block.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleClick = useCallback(async () => {
    if (!clickable) return;
    setIsClicked(true);
    if (isClicked) return;

    const { firstName, lastName, password, centralTestInfo } = user;
    if (centralTestInfo?.userId && centralTestInfo?.assessmentId) {
      const query = queryGenerator({
        test_id: DEFAULT_TEST_ID,
        candidate_id: centralTestInfo.userId,
      });
      const {
        data: [assessmentData],
      } = await centralTestAxios.post(`${PENDING_ASSESSMENT}${query}`);
      if (assessmentData) {
        window.open(assessmentData.url);
        setIsClicked(false);
        return;
      }
      navigate("/dashboard/personality");
      setIsClicked(false);
      return;
    }

    if (!firstName && !lastName) {
      setIsClicked(false);
      return;
    }

    const createQuery = queryGenerator({
      password,
      lastname: lastName,
      firstname: firstName,
      title_id: 1,
      country_code: "FR",
    });
    const { data } = await centralTestAxios.post(
      `${CREATE_CANDIDATE}${createQuery}`
    );

    const inviteQuery = queryGenerator({
      id: data.id,
      test_id: DEFAULT_TEST_ID,
      test_language_id: 1,
    });
    const {
      data: { link, id },
    } = await centralTestAxios.post(`${INVITE_CANDIDATE}${inviteQuery}`);
    setCentralTestInformation({ assessmentId: id, userId: data.id });

    window.open(link);
  }, [isClicked, user, setCentralTestInformation]);

  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      className={styles.block}
      style={style}
      onClick={isDragging ? undefined : handleClick}
    >
      {isClicked && <div className={styles.loader} />}
      <div className={styles.imageContainer}>
        <img
          className={styles.blockImage}
          src={block.image}
          alt={block.title}
        />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.blockTitle}>{block.title}</p>
        <p className={styles.blockSubTitle}>{block.earn}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: {
    firstName: state.auth.info.firstName,
    lastName: state.auth.info.lastName,
    password: state.auth.password,
    centralTestInfo: state.auth.centralTestInfo,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setCentralTestInformation: (info) =>
    dispatch(actions.setCentralTestInformation(info)),
});

const memoizedDraggableDashboardItem = memo(DraggableDashboardItem);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memoizedDraggableDashboardItem);
