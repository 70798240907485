import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import s from "./register.module.css";
import AuthButton from "../../../components/Button";
import StudentImage from "../../../assets/images/student.png";
import BusinessManImage from "../../../assets/images/businessman.png";
import LeftLogo from "../../../assets/images/white-logo.png";
import RightLogo from "../../../components/SVGs/Logo";
import AuthFooter from "../../../components/AuthFooter";

const RegisterChoose = () => {
  const navigate = useNavigate();
  const [sideToSlide, setSideToSlide] = useState("");

  const handleNavigate = useCallback((e) => {
    const path = e.target.name;
    if (path.includes("student")) {
      setSideToSlide("slide-right");
    } else setSideToSlide("slide-left");
    setTimeout(() => navigate(path), 500);
  }, []);

  const handleSignIn = useCallback((url) => {
    navigate(url);
  }, []);

  return (
    <div className={clsx([s.mainContainer, s[sideToSlide]])}>
      <div className={s.leftBlockWrapper}>
        <div className={s.leftBlock}>
          <div className={s.logo}>
            <img src={LeftLogo} alt="logo" />
          </div>
          <div className={s.text_wrapper}>
            <p className={s.descriptionLeft}>
              Welcome to Match Excellence: the platform that allows you to
              <br /> architect your own educational and career path to help you
              <br /> achieve your vision in life.
            </p>
            <p className={s.descriptionLeft}>
              Match Excellence matches you with a customized set of training
              <br /> and job opportunities that are directly relevant to your
              education, <br /> personality traits, skill sets, and aspirations.
            </p>
            <p className={s.descriptionLeft}>
              Know more about Match Excellence here…
            </p>
            <div className={s.buttonContainer}>
              <AuthButton
                onClick={handleNavigate}
                name="/auth/register-student"
                title="Register as a Talent"
                backgroundColor="#FFFFFF"
                color="#5900F5"
                width="204px"
                height="56px"
                style={{ letterSpacing: "0.5px" }}
              />
            </div>
            <p className={s.link}>
              You already have an account?
              <span
                onClick={() => handleSignIn("/auth/login")}
                className={s.link}
                style={{
                  fontWeight: 900,
                  textDecoration: "underline",
                  marginLeft: 4,
                }}
              >
                Sign In
              </span>
            </p>
          </div>
          <div className={s.bottomImageLeft}>
            <img alt="StudentImage" src={StudentImage} />
          </div>
        </div>
      </div>
      <div className={s.rightBlock}>
        <div className={s.logo}>
          <RightLogo />
        </div>
        <div className={s.text_wrapper}>
          <p className={s.descriptionRight}>
            Join Match Excellence Pro to connect with a talented pool of
            <br /> talents who are a perfect match to your opportunities.
          </p>
          <p className={s.descriptionRight}>
            Know more about Match Excellence here…
          </p>
          <div className={s.buttonContainer}>
            <AuthButton
              width="204px"
              height="56px"
              backgroundColor="#FFFFFF"
              color="#000"
              onClick={handleNavigate}
              name="/auth/register-provider"
              title="Register as Provider"
            />
          </div>
          <p className={s.link}>
            You already have an account?
            <span
              onClick={() => handleSignIn("/auth/login")}
              className={s.link}
              style={{
                fontWeight: 900,
                textDecoration: "underline",
                marginLeft: 4,
              }}
            >
              Sign In
            </span>
          </p>
        </div>
        <div className={s.bottomImageRight}>
          <img alt="BusinessManImage" src={BusinessManImage} />
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default RegisterChoose;
