import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { connect } from "react-redux";
import actions from "../../../store/actions/authActions";

import styles from "./Personality.module.css";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { centralTestAxios, queryGenerator } from "../../../api";
import { ASSESSMENT_DETAILS } from "../../../api/urls";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

// const data = {
//   labels: [
//     "Persuasiveness",
//     "Autonomy",
//     "Flexibility",
//     "Ambition",
//     "Extroversion",
//     "Need for Action",
//     "Emotional Distance",
//     "Persuasiveness",
//     "Autonomy",
//     "Flexibility",
//     "Ambition",
//     "Extroversion",
//     "Need for Action",
//     "Emotional Distance",
//   ],
//   datasets: [
//     {
//       label: "",
//       data: [1, 5, 7, 7, 1, 9, 3, 3, 9, 1, 7, 7, 5, 1],
//       backgroundColor: "rgba(89, 0, 245, 0.3)",
//       borderColor: "rgb(37,30,140)",
//     },
//     {
//       label: "",
//       data: [3, 9, 1, 7, 7, 5, 1, 1, 5, 7, 7, 1, 9, 3],
//       backgroundColor: "rgba(16, 173, 79, 0.3)",
//       borderColor: "rgba(6,255,108,0.3)",
//     },
//   ],
// };

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      grid: {
        circular: true,
      },
      beginAtZero: true,
      ticks: {
        display: false,
        maxTicksLimit: 5,
      },
    },
  },
};

const Personality = ({ user }) => {
  const [candidateData, setCandidateData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const getCandidateData = async () => {
      const {
        centralTestInfo: { assessmentId },
      } = user;
      if (!assessmentId) return;
      setLabels([]);

      const query = queryGenerator({
        assessment_id: assessmentId,
      });

      const { data: centralTestData } = await centralTestAxios.post(
        `${ASSESSMENT_DETAILS}${query}`
      );

      setLabels(centralTestData.map((el) => el.factor_name));
      setCandidateData(centralTestData.map((el) => el.factor_score));
    };
    getCandidateData();
  }, [user]);

  const normalizeData = useMemo(() => {
    if (!candidateData.length) return {};
    const midLength = candidateData.length / 2;
    const dataSet1 = candidateData.slice(0, midLength);
    const dataSet2 = candidateData.slice(midLength, candidateData.length);
    const emptyArray = Array.from({ length: midLength });
    const data1 = [...dataSet1, dataSet2[0]];
    const data2 = [...emptyArray, ...dataSet2, dataSet1[0]];
    return {
      labels,
      datasets: [
        {
          label: "",
          data: data1,
          backgroundColor: "rgba(89, 0, 245, 0.3)",
          borderColor: "rgb(37,30,140)",
        },
        {
          label: "",
          data: data2,
          backgroundColor: "rgba(16, 173, 79, 0.3)",
          borderColor: "rgba(6,255,108,0.3)",
        },
      ],
    };
  }, [candidateData, labels]);

  return (
    <DashboardWrapper>
      <>
        <div
          style={!candidateData.length ? { height: "75%" } : {}}
          className={styles.infoContainer}
        >
          <p
            style={
              !candidateData.length
                ? {
                    width: "100%",
                    textAlign: "center",
                    margin: "auto 0",
                  }
                : {}
            }
            className={styles.infoText}
          >
            {!candidateData.length && "No "}Test Results
          </p>
          {candidateData.length ? (
            <div className={styles.labelContainer}>
              <p className={styles.infoLabel}>
                <span
                  className={styles.infoIcon}
                  style={{ background: "#5900F5" }}
                />
                Motivations, Aspirations & Values
              </p>
              <p className={styles.infoLabel}>
                <span
                  className={styles.infoIcon}
                  style={{ background: "#10AD4F" }}
                />
                Behaviour & Personality
              </p>
            </div>
          ) : null}
        </div>
        {candidateData.length ? (
          <Radar
            width={720}
            height={720}
            data={normalizeData}
            options={options}
          />
        ) : null}
      </>
    </DashboardWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: {
    centralTestInfo: state.auth.centralTestInfo,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setCentralTestInformation: (info) =>
    dispatch(actions.setCentralTestInformation(info)),
});

const memoizedPersonality = memo(Personality);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memoizedPersonality);
