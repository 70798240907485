import React from "react";
import s from "./button.module.css";

const AuthButton = ({
  title,
  color,
  backgroundColor,
  width,
  height,
  onClick,
  name,
  style,
}) => (
  <button
    onClick={onClick}
    type="button"
    name={name}
    className={`${s.btn} ${s.btnText}`}
    style={{
      color,
      backgroundColor,
      width,
      height,
      ...style,
    }}
  >
    {title}
  </button>
);

export default AuthButton;
