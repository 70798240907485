import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        login
        role
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query {
    countriesList {
      value: id
      label: name
    }
  }
`;

const GET_STATE_BY_COUNTRY = gql`
  query getStatesOfCountry($countryId: String!) {
    getStatesOfCountry(countryId: $countryId) {
      value: id
      label: name
    }
  }
`;

const GET_CITY_BY_STATE = gql`
  query getCitiesOfState($stateId: String!) {
    getCitiesOfState(stateId: $stateId) {
      value: id
      label: name
    }
  }
`;

const GET_NATIONALITIES = gql`
  query {
    getAllNationalities {
      value: _id
      label: name
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      email
    }
  }
`;

const GET_CUSTOMER_BY_USER_ID = gql`
  query {
    getCustomerByOwnerId {
      _id
    }
  }
`;

const CREATE_PROFILE = gql`
  mutation createProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      _id
    }
  }
`;

const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file)
  }
`;

export default {
  LOGIN,
  GET_COUNTRIES,
  GET_STATE_BY_COUNTRY,
  GET_CITY_BY_STATE,
  GET_NATIONALITIES,
  CREATE_USER,
  GET_CUSTOMER_BY_USER_ID,
  CREATE_PROFILE,
  UPLOAD_AVATAR,
};
