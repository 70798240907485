import React from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./ProfileIcon.module.scss";

// assets
import avatar from "../../../assets/images/blank-avatar.png";

const ProfileIcon = React.memo(() => {
  const { firstName, accAvatar } = useSelector((state) => state.auth.info);
  return (
    <div className={styles.profile}>
      <div className={styles.profile_block}>
        <CircularProgress
          variant="determinate"
          value={75}
          sx={{
            color: "#5900F5",
          }}
        />
        <img
          src={accAvatar || avatar}
          alt="avatar"
          className={styles.profile_block__image}
        />
        <div className={styles.profile_block__circle} />
      </div>
      <span className={styles.profile__name}>{firstName}</span>
    </div>
  );
});

export default ProfileIcon;
