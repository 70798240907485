/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../../store/actions/authActions";

import styles from "./StudentsRegistration3.module.scss";
import LinkedIn from "../../../assets/images/in.png";
import calIcon from "../../../assets/images/calendar.png";
import "react-datepicker/dist/react-datepicker.css";
// import { StudentRegistrationContext } from "..";

const initialValues = {
  job: "",
  company: "",
  companyCity: "",
  startJob: "",
  endJob: "",
  jobDescription: "",
};

const validationSchema = Yup.object({
  job: Yup.string().required(),
  company: Yup.string().required(),
  companyCity: Yup.string().required(),
  startJob: Yup.string().required(),
  endJob: Yup.string().required(),
  jobDescription: Yup.string().required(),
  workHere: Yup.boolean(),
});

const StudentsRegistrationForm3 = React.memo(({ setUserExperience }) => {
  // const { data } = useContext(StudentRegistrationContext);
  const [workHere, setWorkHere] = useState(false);
  const navigate = useNavigate();

  const nextStep = (values) => {
    setUserExperience({
      jobTitle: values.job,
      companyName: values.company,
      location: values.companyCity,
      description: values.jobDescription,
      startDate: values.startJob.toString(),
      endDate: values.endJob.toString(),
      isWorkingHereNow: workHere,
    });
    navigate("/dashboard/challenges");
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={nextStep}
      validationSchema={validationSchema}
    >
      {({
        touched,
        errors,
        setFieldValue,
        values,
        handleChange,
        handleSubmit,
      }) => (
        <form className={styles.registration1} onSubmit={handleSubmit}>
          <div className={styles.form}>
            <div className={styles.form__column}>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.job && errors.job
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.job}
                  name="job"
                  onChange={handleChange}
                />
                <label className={styles.formLabel}>Job Title</label>
              </div>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.company && errors.company
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.company}
                  name="company"
                  onChange={handleChange}
                />
                <label className={styles.formLabel}>Company</label>
              </div>
              <div className={styles.formItem}>
                <input
                  type="text"
                  className={
                    touched.companyCity && errors.companyCity
                      ? clsx(styles.formInput, styles.formInput__error)
                      : styles.formInput
                  }
                  placeholder={" "}
                  value={values.companyCity}
                  name="companyCity"
                  onChange={handleChange}
                />
                <label className={styles.formLabel}>City, Country</label>
              </div>
            </div>
            <div className={styles.form__column}>
              <div className={styles.date_row}>
                <div
                  className={
                    touched.startJob && errors.startJob
                      ? clsx(styles.date_column, styles.date_column__error)
                      : styles.date_column
                  }
                >
                  <DatePicker
                    selected={values.startJob}
                    onChange={(val) => {
                      setFieldValue("startJob", val);
                    }}
                    placeholderText="Start Date"
                    className={styles.date_column__input}
                    name="startJob"
                    error={!!errors.startJob}
                  />
                  <img
                    src={calIcon}
                    alt="date"
                    className={styles.date_column__icon}
                  />
                </div>
                <div
                  className={
                    touched.endJob && errors.endJob
                      ? clsx(styles.date_column, styles.date_column__error)
                      : styles.date_column
                  }
                >
                  <DatePicker
                    selected={values.endJob}
                    onChange={(val) => {
                      setFieldValue("endJob", val);
                    }}
                    placeholderText="End Date"
                    className={styles.date_column__input}
                    name="endJob"
                    error={!!errors.endJob}
                  />
                  <img
                    src={calIcon}
                    alt="date"
                    className={styles.date_column__icon}
                  />
                </div>
              </div>
              <div className={styles.checkbox}>
                <Checkbox
                  onChange={() => setWorkHere(!workHere)}
                  size="small"
                  name="workHere"
                  checked={workHere}
                  sx={{
                    color: "#5900F5",
                    "&.Mui-checked": {
                      color: "#5900F5",
                    },
                  }}
                />
                <span>I currently work here</span>
              </div>
            </div>
          </div>
          <div className={clsx(styles.formItem, styles.doubleHeight)}>
            <textarea
              className={
                touched.jobDescription && errors.jobDescription
                  ? clsx(styles.formInput, styles.formInput__error)
                  : styles.formInput
              }
              placeholder={" "}
              value={values.jobDescription}
              name="jobDescription"
              onChange={handleChange}
            />
            <label className={styles.formLabel}>Description</label>
          </div>
          <div className={styles.next}>
            <button type="submit" className={styles.next__button}>
              Save
            </button>
          </div>
          <div className={styles.upper_block}>
            <div className={styles.linkedIn}>
              <img src={LinkedIn} alt="" className={styles.linkedIn__image} />
              <span className={styles.linkedIn__text}>
                Import from LinkedIn
              </span>
            </div>
            <div className={styles.new_btn}>New</div>
          </div>
        </form>
      )}
    </Formik>
  );
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserExperience: (experience) =>
    dispatch(actions.setUserExperience(experience)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsRegistrationForm3);
