import React, { memo, useCallback, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import DashboardWrapper from "../../../components/DashboardWrapper";
import styles from "./Challenges.module.css";

import Image1 from "../../../assets/images/Rectangle.png";
import Image2 from "../../../assets/images/Saly-15.png";
import Image3 from "../../../assets/images/Saly-1.png";
import Image4 from "../../../assets/images/calendar 1.png";
import DraggableDashboardItem from "../../../components/DraggableItem";

const defaultBlocks = [
  {
    id: 1,
    image: Image1,
    title: "Complete Psychological test",
    earn: "Earn 50XP",
    clickable: true,
  },
  {
    id: 2,
    image: Image2,
    title: "Complete your 1 Year Vision",
    earn: "Earn 50XP",
  },
  {
    id: 3,
    image: Image3,
    title: "Set up your Career Roadmap",
    earn: "Earn 50XP",
  },
  {
    id: 4,
    image: Image4,
    title: "2nd stage - Job Interview at MISK",
    earn: "We will require you to prepare a case study which to showcase your capabilities and suitabili...",
  },
];

const Challenges = () => {
  const [blocks, setBlocks] = useState(defaultBlocks);
  const [isDragging, setDragging] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10, tolerance: 100 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const handleDragEnd = useCallback((event) => {
    setDragging(false);
    const { active, over } = event;

    if (active.id !== over.id) {
      setBlocks((items) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id);
        const newIndex = items.findIndex(({ id }) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  return (
    <DashboardWrapper>
      <div className={styles.blockContainer}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={blocks.map(({ id }) => id)}
            strategy={rectSortingStrategy}
          >
            {blocks.map((block) => (
              <DraggableDashboardItem
                clickable={block?.clickable}
                isDragging={isDragging}
                key={block.id}
                block={block}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </DashboardWrapper>
  );
};

export default memo(Challenges);
