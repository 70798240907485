import React from "react";

const GoogleIcon = () => (
  <svg width={26} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fill="#fff">
      <path d="M4.067 4.933a.333.333 0 0 0 .453-.04 4.667 4.667 0 0 1 6.88-.086.333.333 0 0 0 .473 0L13.82 2.98a.333.333 0 0 0 0-.473 8 8 0 0 0-11.887.266.333.333 0 0 0 .067.494l2.067 1.666ZM3.493 9.86a.333.333 0 0 0 .094-.353 4.593 4.593 0 0 1 0-2.974.333.333 0 0 0-.107-.366L1.3 4.427a.333.333 0 0 0-.507.113 7.953 7.953 0 0 0 .1 7.1.333.333 0 0 0 .52.093l2.08-1.873ZM16 7a.333.333 0 0 0-.333-.333H9A.333.333 0 0 0 8.667 7v2.666A.333.333 0 0 0 9 10h3.227a4.525 4.525 0 0 1-.76 1.113.333.333 0 0 0 0 .447l1.78 2a.333.333 0 0 0 .486 0A8 8 0 0 0 16 8.4V7ZM10.347 12.307a.334.334 0 0 0-.387-.08 4.62 4.62 0 0 1-5.4-1.087.333.333 0 0 0-.467 0l-2 1.787a.334.334 0 0 0 0 .473 8 8 0 0 0 10 1.5.334.334 0 0 0 .08-.507l-1.826-2.086Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
