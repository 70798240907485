const SET_USER = "SET_USER";
const SET_USER_TOKEN = "SET_USER_TOKEN";
const SET_EXPERIENCE = "SET_EXPERIENCE";
const SET_EDUCATION = "SET_EDUCATION";
const SET_USER_GENERAL_INFO = "SET_USER_GENERAL_INFO";
const SET_CENTRAL_TEST_INFO = "SET_CENTRAL_TEST_INFO";

export default {
  SET_USER,
  SET_USER_TOKEN,
  SET_EXPERIENCE,
  SET_EDUCATION,
  SET_USER_GENERAL_INFO,
  SET_CENTRAL_TEST_INFO,
};
