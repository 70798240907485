/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-cycle */
import React, { useContext, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import actions from "../../../store/actions/authActions";
import { StudentRegistrationContext } from "../index";

import styles from "./StudentsRegistration1.module.scss";
// assets
import photo from "../../../assets/images/blank-avatar.png";
import cross from "../../../assets/images/x.png";
import "./overrideInput.css";
import DropDown from "../../../components/DropDown";
import queries from "../../../api/queries/Auth";

const initialValues = {
  name: "",
  middleName: "",
  lastName: "",
  phone: "",
  description: "",
  country: "",
  city: "",
  state: "",
  nationality: "",
  birthday: null,
  gender: "",
};

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[A-Za-z]/g)
    .required(),
  lastName: Yup.string().required(),
  phone: Yup.string().matches(/^\d+$/).required(),
  country: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  birthday: Yup.string().required(),
  gender: Yup.string().required(),
  nationality: Yup.string().required(),
  middleName: Yup.string().required(),
});

const StudentsRegistrationForm1 = React.memo(({ setUserInformation, user }) => {
  const options = useMemo(
    () => ({
      context: {
        headers: {
          Authorization: user.token ? `Bearer ${user.token}` : "",
        },
      },
    }),
    [user.token]
  );
  const { changeStep, changeData } = useContext(StudentRegistrationContext);
  const { data: countriesData } = useQuery(queries.GET_COUNTRIES);
  const { data: nationalitiesData } = useQuery(queries.GET_NATIONALITIES);
  const { data: customerData } = useQuery(queries.GET_CUSTOMER_BY_USER_ID, {
    context: {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token ? `Bearer ${user.token}` : "",
      },
    },
  });
  // const [uploadAvatar] = useMutation(queries.UPLOAD_AVATAR, options);
  const [createProfile] = useMutation(queries.CREATE_PROFILE, options);

  const [accAvatar, setAvatar] = useState();

  const onFileChange = async ({ target }) => {
    const file = target.files[0];
    // const res = await uploadAvatar({
    //   variables: {
    //     file,
    //   },
    // });
    // console.log({ res });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64Data = reader.result;
      setAvatar(base64Data);
    };
  };

  const formik = useFormik({
    initialValues,
    // eslint-disable-next-line no-use-before-define
    onSubmit: nextStep,
    validationSchema,
  });

  const { data: statesData } = useQuery(queries.GET_STATE_BY_COUNTRY, {
    variables: { countryId: formik.values.country },
  });

  const { data: citiesData } = useQuery(queries.GET_CITY_BY_STATE, {
    variables: { stateId: formik.values.state },
  });

  async function nextStep(values) {
    const normalizePhone = values.phone.split(" ").join("").split("-").join("");
    changeData(values);
    const formattedBirthday = `${
      values.birthday.getMonth() + 1
    }-${values.birthday.getDay()}-${values.birthday.getFullYear()}`;
    console.log(customerData, values, user.token, formattedBirthday);

    const { data } = await createProfile({
      variables: {
        input: {
          // eslint-disable-next-line no-underscore-dangle
          customerId: customerData.getCustomerByOwnerId._id,
          countryId: values.country,
          stateId: values.state,
          cityId: values.city,
          firstName: values.name,
          lastName: values.lastName,
          nationality: values.nationality,
          dateOfBirth: formattedBirthday,
          gender: values.gender,
          email: user.email,
          phoneNumber: normalizePhone,
        },
      },
    });
    console.log({ profileData: data });

    setUserInformation({
      firstName: values.name,
      lastName: values.lastName,
      phone: normalizePhone,
      description: values.description,
      linkedinProfile: values.linkedIn,
      accAvatar,
    });
    changeStep(2);
  }

  return (
    <div className={styles.registration1}>
      <div className={styles.registration1__image_wrapper}>
        <div className={styles.registration1__image_block}>
          {!accAvatar && (
            <img
              className={styles.registration1__image}
              src={photo}
              alt="avatar"
            />
          )}
          {accAvatar && (
            <>
              <img
                className={styles.registration1__image}
                src={accAvatar}
                alt="avatar"
              />
              <div
                className={styles.registration1__image__delete}
                onClick={() => setAvatar()}
              >
                <img src={cross} alt="cross" />
              </div>
            </>
          )}
        </div>
        <div className={styles.registration1__upload}>
          <input type="file" onChange={onFileChange} />
          Upload Different Picture
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.form__column}>
          <div className={styles.formItem}>
            <input
              type="text"
              className={
                formik.touched?.name && formik.errors?.name
                  ? clsx(styles.formInput, styles.formInput__input_error)
                  : styles.formInput
              }
              onBlur={formik.handleBlur}
              placeholder={" "}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <label className={styles.formLabel}>First Name</label>
          </div>
          <div className={styles.formItem}>
            <input
              type="text"
              className={
                formik.touched?.middleName && formik.errors?.middleName
                  ? clsx(styles.formInput, styles.formInput__input_error)
                  : styles.formInput
              }
              onBlur={formik.handleBlur}
              placeholder={" "}
              name="middleName"
              onChange={formik.handleChange}
              value={formik.values.middleName}
            />
            <label className={styles.formLabel}>Middle Name</label>
          </div>
          <div className={styles.formItem}>
            <input
              type="text"
              placeholder={" "}
              onBlur={formik.handleBlur}
              className={
                formik.touched.lastName && formik.errors?.lastName
                  ? clsx(styles.formInput, styles.formInput__input_error)
                  : styles.formInput
              }
              value={formik.values.lastName}
              name="lastName"
              onChange={formik.handleChange}
            />
            <label className={styles.formLabel}>Last Name</label>
          </div>
          <PhoneInput
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue("phone", value)}
            defaultErrorMessage={formik.errors.phone}
            isValid={() =>
              formik.touched.phone ? !formik.errors?.phone : true
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Birthday"
              name="birthday"
              value={formik.values.birthday}
              onChange={(newValue) => {
                formik.setFieldValue("birthday", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  error={formik.touched.birthday && !formik.errors?.birthday}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "10px",
                    svg: { color: "rgba(255, 255, 255, 0.5)" },
                    input: {
                      color: "#fff",
                      borderColor: "rgba(255, 255, 255, 0.1)",
                    },
                    fieldSet: {
                      color: "#fff",
                      borderColor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "10px",
                    },
                    label: {
                      color: "#fff",
                      opacity: 0.4,
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input":
                      {
                        color: "white",
                        borderRadius: "10px",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "white",
                      borderRadius: "10px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "white",
                        borderWidth: "1px",
                        borderRadius: "10px",
                      },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "white",
                      },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.form__column}>
          <DropDown
            onChange={formik.handleChange}
            value={formik.values.country}
            label="Country"
            options={countriesData?.countriesList ?? []}
            name="country"
            error={formik.touched.country && formik.errors?.country}
            disabled={countriesData?.countriesList?.length === 0}
          />
          <DropDown
            onChange={formik.handleChange}
            value={formik.values.state}
            label="State"
            options={statesData?.getStatesOfCountry ?? []}
            name="state"
            error={formik.touched.state && formik.errors?.state}
            disabled={!formik.values.country.length}
          />
          <DropDown
            onChange={formik.handleChange}
            value={formik.values.city}
            label="City"
            options={citiesData?.getCitiesOfState ?? []}
            name="city"
            error={formik.touched.city && formik.errors?.city}
            disabled={!formik.values.state.length}
          />
          <DropDown
            onChange={formik.handleChange}
            value={formik.values.nationality}
            label="Nationality"
            options={nationalitiesData?.getAllNationalities ?? []}
            name="nationality"
            error={formik.touched.nationality && formik.errors?.nationality}
          />
          <DropDown
            onChange={formik.handleChange}
            value={formik.values.gender}
            label="Gender"
            error={formik.touched.gender && formik.errors?.gender}
            options={[
              { value: "MALE", label: "Male" },
              { value: "FEMALE", label: "Female" },
            ]}
            name="gender"
          />
        </div>
      </div>
      <div className={styles.next}>
        <button
          type="button"
          className={styles.next__button}
          onClick={formik.handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({ user: state.auth });

const mapDispatchToProps = (dispatch) => ({
  setUserInformation: (info) => dispatch(actions.setUserInformation(info)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsRegistrationForm1);
