import React from "react";

const FacebookIcon = () => (
  <svg width={26} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M12.093 4.78a.334.334 0 0 0-.246-.113H9.333v-.94c0-.187.04-.4.34-.4h2a.293.293 0 0 0 .234-.1A.333.333 0 0 0 12 3V.333A.333.333 0 0 0 11.667 0H8.78c-3.2 0-3.447 2.733-3.447 3.567v1.1H3.667A.333.333 0 0 0 3.333 5v2.667A.333.333 0 0 0 3.667 8h1.666v7.667a.333.333 0 0 0 .334.333H9a.333.333 0 0 0 .333-.333V8h2.234a.333.333 0 0 0 .333-.3l.28-2.667a.331.331 0 0 0-.087-.253Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookIcon;
