/* eslint-disable import/no-cycle */
import React from "react";
import clsx from "clsx";
import Wrapper from "../../../components/Wrapper/Wrapper";
import SideBar from "../../../components/SideBar/SideBar";
import Main from "../../../components/Main/Main";
import progressStyles from "../../../components/SideBar/ProgressWrapper/InnerContent.module.scss";
import ProgressWrapper from "../../../components/SideBar/ProgressWrapper/ProgressWrapper";
import StudentsRegistrationForm1 from "./StudentsRegistrationForm1";

// assets
import walkman from "../../../assets/images/walkman.png";

const StudentsRegistration1 = () => (
  <Main>
    <Wrapper title="General Information">
      <StudentsRegistrationForm1 />
    </Wrapper>
    <SideBar>
      <ProgressWrapper value={5}>
        <div className={progressStyles.inner_block}>
          <span className={progressStyles.header}>5%</span>
          <span className={progressStyles.text}>Profile Completion</span>
        </div>
      </ProgressWrapper>
      <span className={clsx([progressStyles.header, progressStyles.mt_40])}>
        Complete your profile & earn 15 XP
      </span>
      <span className={clsx([progressStyles.text, progressStyles.mt_12])}>
        3 steps left
      </span>
      <img className={progressStyles.mt_60} src={walkman} alt="walkman" />
    </SideBar>
  </Main>
);

export default StudentsRegistration1;
