import React from "react";
import styles from "./StatsBlock.module.scss";

// assets
import buy from "../../../assets/images/Buy.png";

const StatsBlock = React.memo(() => (
  <div className={styles.stats}>
    <span className={styles.stats_title}>Live Ranking</span>
    <span className={styles.stats_text}>#58</span>
    <div className={styles.stick} />
    <span className={styles.stats_title}>Level 7</span>
    <span className={styles.stats_text}>640 / 700XP</span>
    <div className={styles.stick} />
    <span className={styles.stats_title}>Coins</span>
    <span className={styles.stats_text}>70</span>
    <div className={styles.image_wrapper}>
      <img className={styles.image_wrapper__image} src={buy} alt="buy" />
    </div>
  </div>
));

export default StatsBlock;
