import React, { useRef } from "react";
import styles from "./DropFiles.module.scss";
import arrowUp from "../../assets/images/arrowUp.png";

const DropFiles = () => {
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  return (
    // {
    //     fileData.length ? (
    //         <div className="drop-file-preview">
    //             {fileData.map(item => (
    //                 <div key={item.id} className="drop-file-preview__item">
    //                     <img src={item.filepath || item.file || logo} alt="" />
    //                     <div className="drop-file-preview__item__info">
    //                         <p>{item.name || item.filename}</p>
    //                         <p>{item.size}</p>
    //                     </div>

    //                     <div
    //                         className="drop-file-preview__item__del"
    //                         onClick={fileRemove}
    //                         id={item.id}
    //                     >
    //                         <span id={item.id}>X</span>
    //                     </div>
    //                 </div>
    //             ))}
    //         </div>
    //     ) : (
    <div
      ref={wrapperRef}
      className={styles.drop_file_input}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className={styles.drop_file_input__label}>
        <img src={arrowUp} alt="" />
        <p>Upload scanned certificates & transcripts</p>
      </div>
      <input type="file" value="" onChange={onDrop} />
    </div>
    //         )
    //     }
  );
};

export default DropFiles;
