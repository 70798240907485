import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import ProfileIcon from "./ProfileIcon/ProfileIcon";
import { ReactComponent as SunIcon } from "../../assets/images/fi_sun.svg";
// assets
import logo from "../../assets/images/logo.png";
import chat from "../../assets/images/chat.png";
import users from "../../assets/images/3-users.png";
import focus from "../../assets/images/focus-icon.png";
import home from "../../assets/images/home.png";
import StatsBlock from "./StatsBlock/StatsBlock";

const switchStyles = {
  "& .MuiSwitch-track": {
    width: "60px",
    height: "30px",
    background: "#C3BAF3",
    opacity: "0.2",
    boxShadow: "inset 0px 1px 4px #18191D",
    borderRadius: "40px",
  },
  "&.MuiSwitch-root": {
    padding: "0 !important",
    alignItems: "center",
  },
  "& .MuiButtonBase-root": {
    width: "26px",
    height: "26px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "#8400ee !important",
    boxShadow: "0 0 7px rgba(24, 25, 29, 0.5)",
  },
  "& .Mui-checked": {
    transform: "translate(100%, -50%)!important",
  },
  "& .MuiSwitch-thumb": {
    display: "none",
  },
};

const Header = React.memo(({ showStats = true }) => {
  const [messages] = useState([]);

  return (
    <div className={styles.header}>
      <div className={styles.header__container}>
        <Link to="/dashboard/challenges">
          <img src={logo} alt="logo" />
        </Link>
        <div className={styles.icons}>
          <Switch
            sx={switchStyles}
            icon={<SunIcon />}
            checkedIcon={<SunIcon />}
          />
          {showStats && (
            <>
              <div className={styles.stick} />
              <StatsBlock />
            </>
          )}
          <div className={styles.stick} />
          <div className={styles.icons__block}>
            <img alt="icon" src={home} />
          </div>
          <div className={styles.icons__block}>
            <img alt="icon" src={users} />
          </div>
          <div className={styles.icons__block}>
            <img alt="icon" src={focus} />
          </div>
          <div className={styles.icons__block}>
            <img alt="icon" src={chat} />
            {messages.length > 0 && (
              <div className={styles.icons__block__amount}>
                <span>{messages.length}</span>
              </div>
            )}
          </div>
          <div className={styles.stick} />
          <ProfileIcon />
        </div>
      </div>
    </div>
  );
});

export default Header;
