/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import actions from "../../../store/actions/authActions";
import queries from "../../../api/queries/Auth";

import s from "./register-student.module.css";
import AuthFooter from "../../../components/AuthFooter";
import WhiteLogo from "../../../assets/images/white-logo.png";
import AuthButton from "../../../components/Button";
import SocialButton from "../../../components/SocialButton";
import FacebookIcon from "../../../components/SVGs/Media/facebook";
import GoogleIcon from "../../../components/SVGs/Media/google";
import RightLogo from "../../../components/SVGs/Logo";
import SubscribeImage from "../../../assets/images/subscribeLogo.png";
import RocketIcon from "../../../assets/images/Saly-1.png";
import pageTypes from "../../../utils/constants";

const theme = {
  student: {
    container: {
      background: "#000000",
    },
    registerButton: {
      backgroundColor: "#5900F5",
      color: "#fff",
    },
    coloredContainer: {
      background: "linear-gradient(63.82deg, #5900f5 4.17%, #8400ee 96.17%)",
    },
    title: {
      color: "#18191d",
    },
    rectangleBlock: {
      background: "#fff",
    },
    input: {
      background: "rgba(24, 25, 29, 0.05)",
      color: "black",
    },
    textAnotherVariant: {
      color: "#18191d",
    },
    "input::placeholder": {
      color: "#18191d",
    },
    haveAccount: {
      color: "#18191d",
    },
    link: {
      color: "#610df6",
      marginLeft: "4px",
    },

    clickBlock: {
      order: 2,
    },
  },
  provider: {
    container: {
      background: "linear-gradient(63.82deg, #5900F5 4.17%, #8400EE 96.17%)",
    },
    registerButton: {
      backgroundColor: "fff",
      color: "#000000",
    },
    coloredContainer: {
      marginLeft: "auto",
      marginRight: 0,
      background: "#18191D",
    },
    title: {
      color: "#fff",
    },
    rectangleBlock: {
      background: "#24262C",
    },
    input: {
      background: "rgba(255, 255, 255, 0.05)",
      color: "#fff",
    },
    textAnotherVariant: {
      color: "#fff",
    },
    "input::placeholder": {
      color: "#fff", // 18191d
    },
    haveAccount: {
      color: "#fff",
    },
    link: {
      color: "#fff",
      marginLeft: "4px",
    },
  },
  subscribe: {
    container: {
      background: "#18191D",
    },
    registerButton: {
      backgroundColor: "#5900F5",
      color: "#fff",
    },
    coloredContainer: {
      marginLeft: "auto",
      marginRight: 0,
      background: "#18191D",
    },
    title: {
      color: "#fff",
    },
    rectangleBlock: {
      background: "#24262C",
    },
    input: {
      background: "rgba(255, 255, 255, 0.05)",
      color: "#fff",
    },
    textAnotherVariant: {
      color: "#fff",
    },
    "input::placeholder": {
      color: "#fff", // 18191d
    },
    haveAccount: {
      color: "#fff",
    },
    link: {
      color: "#fff",
      marginLeft: "4px",
    },
    clickBlock: {
      display: "none",
    },
  },
};

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Required"),
});

const userRoles = { student: "USER", provider: "CUSTOMER" };

const Register = ({
  title,
  type,
  setUserEmailAndPassword,
  setUserToken,
  pageType = pageTypes.REGISTER,
}) => {
  const [hide, setHidden] = useState();
  const style = theme[type];
  const navigate = useNavigate();
  const isLogin = useMemo(() => pageType === pageTypes.LOGIN, [pageType]);
  const [login] = useMutation(queries.LOGIN);
  const [register] = useMutation(queries.CREATE_USER);

  const handleSignIn = useCallback(
    async (values) => {
      if (isLogin) {
        const { data: loginData } = await login({
          variables: {
            input: {
              email: values.email,
              password: values.password,
            },
          },
        });
        if (loginData) {
          console.log({ loginData });
          setUserToken({ token: loginData.login.token });
          navigate("/dashboard/challenges");
        }
        return;
      }

      const { data } = await register({
        variables: {
          input: {
            email: values.email,
            password: values.password,
            role: userRoles[type],
          },
        },
      });
      console.log(data);
      setUserEmailAndPassword(values);
      if (data) {
        const { data: loginData } = await login({
          variables: {
            input: {
              email: values.email,
              password: values.password,
            },
          },
        });
        console.log(loginData);
        if (loginData) {
          setUserToken({ token: loginData.login.token });
          navigate(
            isLogin ? "/dashboard/challenges" : "/auth/registration-student"
          );
        }
      }
      // if (data?.login?.token) {
      //   setUserToken({ token: data.login.token });
      //   navigate("/dashboard/challenges");
      // }
      // console.log({ data });
    },
    [setUserEmailAndPassword, setUserToken, isLogin]
  );

  const handleBackClick = () => {
    setTimeout(() => navigate("/auth"), 500);
    setHidden("hide");
  };

  const handleSignInOrSignUp = useCallback(() => {
    if (!isLogin) navigate("/auth/login");
    else navigate("/auth/registration-student");
  }, [isLogin, navigate]);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSignIn,
    validationSchema,
  });

  return (
    <div style={style.container} className={s.container}>
      <div
        style={style.clickBlock}
        className={s.animationBlock}
        onClick={handleBackClick}
      />
      <div
        style={style.coloredContainer}
        className={clsx(
          s.coloredContainer,
          type === "provider" ? s.willSlideRight : s.willSlideLeft,
          hide ? (type === "provider" ? s.slideRight : s.slideLeft) : ""
        )}
      >
        <div className={clsx(s.willHide, s[hide])}>
          <div className={s.logo}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {type === "provider" ? (
              <RightLogo />
            ) : type === "subscribe" ? (
              <img src={SubscribeImage} alt="SubscribeImage" />
            ) : (
              <img src={WhiteLogo} alt="whiteLogo" />
            )}
          </div>
          <div style={style.rectangleBlock} className={s.rectangleBlock}>
            <div className={s.titleBlock}>
              <p style={style.title} className={s.title}>
                {title}
              </p>
            </div>
            <div className={s.form}>
              <input
                style={style.input}
                className={clsx(
                  s.input,
                  formik.errors.email ? s.inputError : ""
                )}
                value={formik.values.email}
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Email Address"
                onChange={formik.handleChange}
              />
              {!!formik.errors.email && (
                <label htmlFor={style.input} className={s.errorLabel}>
                  {formik.errors.email}
                </label>
              )}
              <input
                style={style.input}
                type="password"
                autoComplete="current-password"
                className={clsx(
                  s.input,
                  formik.errors.password ? s.inputError : ""
                )}
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && (
                <label htmlFor={style.input} className={s.errorLabel}>
                  {formik.errors.password}
                </label>
              )}
              <div className={s.buttonBlock}>
                <AuthButton
                  style={{ letterSpacing: 1 }}
                  width="156px"
                  height="56px"
                  title={
                    type === "subscribe"
                      ? "Sign Up"
                      : isLogin
                      ? "Sign In"
                      : "Register"
                  }
                  onClick={formik.handleSubmit}
                  backgroundColor={style.registerButton.backgroundColor}
                  color={style.registerButton.color}
                />
                {type !== "subscribe" && (
                  <p style={style.haveAccount} className={s.haveAccount}>
                    {isLogin
                      ? "Don't have an account yet?"
                      : "Already have account?"}
                    <span
                      onClick={handleSignInOrSignUp}
                      style={style.link}
                      className={s.link}
                    >
                      {isLogin ? "Sign up" : "Sign In"}
                    </span>
                  </p>
                )}
              </div>
              <div className={s.mediaBlock}>
                <p
                  style={style.textAnotherVariant}
                  className={s.textAnotherVariant}
                >
                  Or you can subscribe via social media accounts:
                </p>
                <div className={s.mediaButtons}>
                  <SocialButton
                    style={{ height: 46, width: 241 }}
                    Icon={FacebookIcon}
                    title="Subscribe with Facebook"
                    backgroundColor="#3B4DB3"
                  />
                  <SocialButton
                    style={{ marginLeft: 12, height: 46, width: 241 }}
                    Icon={GoogleIcon}
                    title="Subscribe with Google"
                    backgroundColor="#DA3D29"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {type === "subscribe" && (
          <div
            style={{
              position: "absolute",
              right: -60,
              bottom: -50,
              overflow: "hidden",
            }}
          >
            <img src={RocketIcon} alt="RocketIcon" />
          </div>
        )}
        <AuthFooter />
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserEmailAndPassword: (obj) =>
    dispatch(actions.setUserEmailAndPassword(obj)),
  setUserToken: (tokenObj) => dispatch(actions.setUserToken(tokenObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
