import React from "react";
import styles from "./Main.module.scss";

const Main = ({ children }) => (
  <div className={styles.main}>
    <div className={styles.main__block}>{children}</div>
  </div>
);

export default Main;
