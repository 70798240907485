import React from "react";
import s from "./social-button.module.css";

const SocialButton = ({ title, backgroundColor, Icon, style }) => (
  <button
    type="button"
    className={`${s.btn} ${s.btnText}`}
    style={{ backgroundColor, ...style }}
  >
    <Icon /> {title}
  </button>
);

export default SocialButton;
