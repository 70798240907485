import axios from "axios";

const getHeaders = () => ({
  "WWW-Authenticate": "{SSHA}6VefhjzeTrrFKj0xGR33epG20qSod8GN",
});

const config = {
  baseURL: "https://app.centraltest.com/customer/REST",
  headers: getHeaders(),
};

const centralTestAxios = axios.create(config);

const queryGenerator = (obj) => {
  const keys = Object.keys(obj);
  const query = keys.map((el) => `${el}=${obj[el]}`);
  return query.join("&");
};

export { queryGenerator, centralTestAxios };
